import styled from '@emotion/styled';

import { BaseHero } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import { SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';
import Image from '../../../Image';

export const STACKED_PHOTO_LENGTH = 550;
const sharedPhotoStyles = `
  width: ${STACKED_PHOTO_LENGTH}px;
  height: ${STACKED_PHOTO_LENGTH}px;
  position: relative;
`;

export const TopPhotoSized = styled(Image)<{ hasAnimation: boolean }>`
  ${sharedPhotoStyles}
  z-index: ${ZINDEX.middle};
  top: ${props => (props.hasAnimation ? '0' : `-${SPACING.LG}`)};
  right: ${props => (props.hasAnimation ? '0' : `-${SPACING.LG}`)};
`;

export const BottomPhotoSized = styled(Image)<{ hasAnimation: boolean }>`
  ${sharedPhotoStyles}
  z-index: ${ZINDEX.top};
  bottom:  ${props => (props.hasAnimation ? '0' : `-${SPACING.LG}`)};
  left: ${props => (props.hasAnimation ? '0' : `-${SPACING.LG}`)};
`;

export const DesktopImagesContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

export const StyledDesktopBaseHero = styled(BaseHero)`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;
